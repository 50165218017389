<script setup lang="ts">
const { onLogout, user } = useLoginStore()

const items = [
  [{
    slot: 'account',
    label: '',
    disabled: true,
  }],
  [{
    label: '退出登录',
    icon: 'heroicons:arrow-left-on-rectangle',
    click: async () => {
      await onLogout()
      await navigateTo('/')
    },
  }],
]
</script>

<template>
  <UDropdown
    mode="hover"
    :items="items"
    :ui="{
      width: 'w-full',
      item: {
        size: 'max-sm:text-lg',
        disabled: 'cursor-text select-text',
        icon: { base: 'max-sm:size-7' },
      },
    }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UDashboardSidebarLinks
        class="w-full max-sm:space-y-1"
        :links="[{
          avatar: { src: user?.face, referrerpolicy: 'same-origin' },
          label: user?.name,
          active: open,
        }]"
        :ui="{
          label: 'max-sm:text-lg',
          icon: { base: 'max-sm:size-6' },
        }"
      >
        <template #badge>
          <Icon
            name="heroicons:ellipsis-vertical"
            class="size-7 sm:size-5 ml-auto"
          />
        </template>
      </UDashboardSidebarLinks>
    </template>

    <template #account>
      <div class="text-left max-sm:text-lg">
        <p>
          用户
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          UID:{{ user?.uid }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>
